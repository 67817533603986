import React from "react"
import Layout from "../components/layout"
import Clutch from "../components/clutch"
import { Link } from "gatsby"
import WomenWork__rs from "../images/womenwork.png"
import Petdog__rs from "../images/petdog.png"
import Amazon_case from "../images/amazon_casestudy.jpg"
import Atg_website from "../images/atg-website-img1.png"
import {Helmet} from "react-helmet/es/Helmet";

export default () => (
  <Layout>
    <Helmet>
      <title>
        Contact Us | Get in Touch with Cueblocks </title>
      <meta name="viewport" content="initial-scale=1.0, width=device-width, maximum-scale=1, user-scalable=no"/>
      <meta charSet="utf-8"/>
      <link rel="canonical" href="https://www.cueblocks.com/contact.php"/>
      <meta name="description"
            content="Want to get in touch?  We'd love to hear from you. Contact us now by filling this form."/>
      <meta property="og:site_name" content="Cueblocks"/>
      <meta property="fb:app_id" content="289086684439915"/>
      <meta property="og:url" content="https://www.cueblocks.com/contact.php"/>
      <meta property="og:title" content="Contact Us | Get in Touch with Cueblocks"/>
      <meta property="og:description"
            content="Want to get in touch?  We'd love to hear from you. Contact us now by filling this form."/>
      <meta property="og:image" content="https://www.cueblocks.com/images/cb.jpg"/>

      <meta name="twitter:card" content="summary_large_image"/>
      <meta name="twitter:description"
            content="Want to get in touch?  We'd love to hear from you. Contact us now by filling this form."/>
      <meta name="twitter:title" content="Contact Us | Get in Touch with Cueblocks"/>
      <meta name="twitter:site" content="@cueblocks"/>
      <meta name="twitter:image" content="https://www.cueblocks.com/images/cb.jpg"/>
    </Helmet>
    <section className=" resourace-casestudy">
      <div className="container">
        <h2 className="heading_main">case study</h2>
        <div className="ser_sec_row_lt pd_right_40">
          <div className="ser_sec_row_lt_text">
            <p>
               Meticulously honing our skills over the last 15 years, we constantly strive to deliver on our promise of in-depth client collaborations fueled by clear & consistent communication. 
            </p>
          </div>
        </div>
        <div className="ser_sec_row_rt pd_left_40">
          <div className="ser_sec_row_lt_text">
            <p>
               Adhering to timing & budget expectations of our clients, we build & modify the solutions their customers are looking for, thereby reinforcing the brand identity in the industry. Take an in-depth look into how we sailed through a few of our projects. 
            </p>
          </div>
        </div>
      </div>
    </section>

    <section className=" resources-petdoors fleex">
      <div className="container">
        <div className="print-outer">
          <div className="ser_sec_row_lt pd_right_40">
            <img
              src={WomenWork__rs}
              alt="Cue Blocks"
              className="img-responsive"
            />
          </div>
          <div className="ser_sec_row_rt pd_left_40 ">
            <h2 className="heading_main">Womanswork</h2>
            <span className="highlight-span">Shopify,SEO</span>
            <h4>
               Ensuring speed optimization for Womanswork's Magento 2 eStore.
            </h4>
            <div className="ser_sec_row_lt_text"></div>
            <Link to="/site-speed-ww/" className="btn main_cta">
              Read more
            </Link>
          </div>
        </div>
      </div>
    </section>

    <section className=" resources-petdoors fleex right-img">
      <div className="container">
        <div className="print-outer">
          <div className="ser_sec_row_rt pd_left_40">
            <img src={Petdog__rs} alt="Cue Blocks" className="img-responsive" />
          </div>
          <div className="ser_sec_row_lt pd_right_40">
            <h2 className="heading_main">PetDoors</h2>
            <span className="highlight-span">magento</span>
            <h4>
              Merging revenue-driven blogs to increase organic sessions by 106.94% for PetDoors.
            </h4>
            <div className="ser_sec_row_lt_text"></div>
            <Link to="/merging-blog-pages-seo" className="btn main_cta">
              Read more
            </Link>
          </div>
        </div>
      </div>
    </section>
    <section className=" resources-petdoors fleex">
      <div className="container">
        <div className="print-outer">
          <div className="ser_sec_row_lt pd_right_40">
            <img
              src={Amazon_case}
              alt="Cue Blocks"
              className="img-responsive"
            />
          </div>
          <div className="ser_sec_row_rt pd_left_40 ">
            <h2 className="heading_main">AllThatGrows</h2>
            <span className="highlight-span">Marketplace</span>
            <h4>
              Leveraging the Amazon Marketplace to increase revenue for AllThatGrows. 
            </h4>
            <div className="ser_sec_row_lt_text"></div>
            <Link to="/amazon-marketplace" className="btn main_cta">
              Read more
            </Link>
          </div>
        </div>
      </div>
    </section>

    <section className=" resources-petdoors fleex right-img">
      <div className="container">
        <div className="print-outer">
          <div className="ser_sec_row_rt pd_left_40">
            <img
              src={Atg_website}
              alt="Cue Blocks"
              className="img-responsive"
            />
          </div>
          <div className="ser_sec_row_lt pd_right_40">
            <h2 className="heading_main">AllThatGrows</h2>
            <span className="highlight-span">Shopify</span>
            <h4>
              Building brand identity, packaging designs & marketing campaigns for AllThatGrows.
            </h4>
            <div className="ser_sec_row_lt_text"></div>
            <Link to="/atg_brand" className="btn main_cta">
              Read more
            </Link>
          </div>
        </div>
      </div>
    </section>

    <section className="home-resourse__row home-resourse__row_res">
      <div className="container">
        <h2 className="heading_main">Resources</h2>
        <div className="resourse__lt pd_right_40">
          <p>
            Be sure to check off all the points on this specially-curated Conversion Rate Optimisation (CRO) before investing in your next big eCommerce venture.
          </p>
          <h3>91 Point CRO Checklist & Infographic</h3>
          <a href={'/Holy-Grail-of-eCommerce-Conversion-Optimization-91-Point-Checklist-and-Infographic.pdf'} className="main_cta" download>
            Download
          </a>
        </div>
        <div className="resourse__rt pd_left_40">
          <p>
            Follow our 40-Point Checklist Guide for quick tips & feasible steps to smoothly switch the HTTP version of your website to a more secure version, HTTPS.
          </p>
          <h3>40 Point Checklist for a Successful HTTP to HTTPS Migration </h3>
          <a href={'/blog-Infographics.pdf'} className="main_cta" download>
            Download
          </a>
        </div>
      </div>
    </section>

    <Clutch />
  </Layout>
)
